import { ConfigureOptions, Storage } from '@capacitor/storage'

interface ICapacitorStorage {
  get: (key: string) => Promise<string | null>
  set: (key: string, value: string) => Promise<void>
  remove: (key: string) => Promise<void>
  clear: () => Promise<void>
  removeOld: () => Promise<void>
  migrate: () => Promise<void>
  keys: () => Promise<string[]>
  configure: (options: ConfigureOptions) => Promise<void>
}

export default class CapacitorStorage implements ICapacitorStorage {
  async get (key: string) {
    const result = await Storage.get({ key })
    return result.value
  }

  async set (key: string, value: string) {
    await Storage.set({ key, value })
  }

  async remove (key: string) {
    await Storage.remove({ key })
  }

  async clear () {
    await Storage.clear()
  }

  async removeOld () {
    await Storage.removeOld()
  }

  async migrate () {
    await Storage.migrate()
  }

  async keys () {
    const result = await Storage.keys()
    return result.keys
  }

  async configure (options: ConfigureOptions) {
    return await Storage.configure(options)
  }
}
